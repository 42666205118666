var PermSet_1;
import { __decorate } from "tslib";
import { Component, Vue, Ref, Prop, } from 'vue-property-decorator';
import { PermManageService } from '@/sevices';
import TreeUtils from '@/common/TreeUtils';
var Navigation;
(function (Navigation) {
    Navigation["AppMange"] = "app-manage";
    Navigation["WorkflowManage"] = "workflow-manage";
    Navigation["Organization"] = "org-user";
    Navigation["SysManage"] = "sys-manage";
})(Navigation || (Navigation = {}));
let PermSet = PermSet_1 = class PermSet extends Vue {
    constructor() {
        super(...arguments);
        this.activeKey = '';
        this.Navigation = Navigation;
        this.originalPermList = [];
        this.permList = [];
        // 不显示的项
        this.hideItem = [
            'org-tag-manage',
        ];
    }
    get commonPermList() {
        return this.permList.filter((item) => {
            return item.code !== Navigation.AppMange;
        });
    }
    get appPermList() {
        let result = [];
        this.permList.forEach((item) => {
            if (item.code === Navigation.AppMange) {
                result = item.children;
            }
        });
        return result;
    }
    get roleDetailPermData() {
        return this.roleDetail?.permList || [];
    }
    get roleDetailAppPermData() {
        return this.roleDetail?.appPerms || [];
    }
    get roleDetailAppType() {
        let result = '';
        for (const item of this.roleDetailPermData) {
            if (item.code === Navigation.AppMange) {
                result = item.value;
                break;
            }
        }
        return result;
    }
    created() {
        this.getPermList();
    }
    getNavigation(parentId) {
        let result = {};
        for (const item of this.originalPermList) {
            if (item.id === parentId) {
                if (item.type !== PermSet_1.NAVIGATION) {
                    result = this.getNavigation(item.parentId);
                }
                else {
                    result = item;
                }
                if (result.name) {
                    break;
                }
            }
        }
        return result;
    }
    getParams() {
        const commonPermList = [];
        const permList = JSON.parse(JSON.stringify(this.permList));
        TreeUtils.forEachTree(permList, (item) => {
            let appType = '';
            if (item.code === Navigation.AppMange) {
                appType = this.permApp.getAppType();
                if (appType) {
                    item.isChecked = true;
                }
            }
            if (item.isChecked || item.indeterminate) {
                const resItem = this.originalPermList.find(res => res.id === item.id);
                if (item.code === Navigation.AppMange) {
                    resItem.value = appType;
                }
                commonPermList.push(resItem);
                // 标签管理的子项，需要把标签管理也加上
                if (item.parentId === '2c9f8c267660f8b501766105aac60022') {
                    const labelManage = this.originalPermList.find(res => res.id === item.parentId);
                    if (!commonPermList.some(perm => perm.code === labelManage.code)) {
                        commonPermList.push(labelManage);
                    }
                }
                if (item.parentId) {
                    const nav = this.getNavigation(item.parentId);
                    if (nav.name && !commonPermList.some(perm => perm.code === nav.code)) {
                        commonPermList.push(nav);
                    }
                }
            }
        });
        const appPermList = this.permApp.getParams();
        return {
            commonPermList: commonPermList,
            appPermList: appPermList,
        };
    }
    async getPermList() {
        const res = await PermManageService.getAllRolePerm();
        if (!res.success)
            return;
        this.originalPermList = res.data;
        this.permList = this.filterPermList(this.sort(this.getPermTree(res.data)));
        this.activeKey = this.permList[0]?.code;
    }
    filterPermList(permList = []) {
        TreeUtils.forEachTree(permList, (item) => {
            if (this.hideItem.includes(item.code)) {
                const { nodeState } = TreeUtils.getTreeNodeStateByKey(item.parentId, 'id', permList);
                if (nodeState) {
                    nodeState.children = [...nodeState.children, ...item.children].filter((child) => {
                        return !this.hideItem.includes(child.code);
                    });
                }
            }
        });
        return permList;
    }
    /**
     * 排序
     */
    sort(permList = []) {
        const sort = (list) => {
            const sortList = list.sort((a, b) => a.sort - b.sort);
            for (let i = 0, len = sortList.length; i < len; i++) {
                const item = sortList[i];
                if (item.children && item.children.length) {
                    item.children = sort(item.children);
                }
            }
            return sortList;
        };
        const result = sort(permList);
        return result;
    }
    /**
     * 生成权限树
     * @param allPermList
     */
    getPermTree(allPermList = []) {
        const getTree = (dataTree, parentId = '') => {
            const tree = [];
            let node = {};
            let children = [];
            if (!Array.isArray(dataTree) || !dataTree.length)
                return tree;
            for (let i = 0, len = dataTree.length; i < len; i++) {
                const item = dataTree[i];
                // 权限管理暂时不允许配置
                if (item.code === 'manager-manage')
                    continue;
                if (item.parentId === parentId) {
                    node = {
                        ...item,
                        isChecked: false,
                        indeterminate: false,
                    };
                }
                else {
                    continue;
                }
                children = getTree(dataTree, item.id);
                node.children = children;
                tree.push(node);
            }
            return tree;
        };
        const result = getTree(allPermList);
        return result;
    }
};
// 平台
PermSet.PLATFORM = 0;
// 导航
PermSet.NAVIGATION = 1;
// 菜单
PermSet.MENU = 2;
// 页面元素
PermSet.PAGE_ELEMENT = 3;
__decorate([
    Ref()
], PermSet.prototype, "permApp", void 0);
__decorate([
    Prop()
], PermSet.prototype, "roleDetail", void 0);
PermSet = PermSet_1 = __decorate([
    Component({
        name: 'PermSet',
        components: {
            PermTable: () => import('./perm-table.vue'),
            PermApp: () => import('./perm-app.vue'),
        },
    })
], PermSet);
export default PermSet;
